import React from "react";
import Jumbotron from "./jumbotron";
function Partners() {
  return (
    <>
      <Jumbotron />
    </>
  );
}

export default Partners;
