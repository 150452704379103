const Licenses = [
    {
      device: 1,
      monthly_price: 512,
      annual_price: 3595,
    },
    {
      device: 5,
      monthly_price: 675,
      annual_price: 4740,
    },
    {
      device: 10,
      monthly_price: 925,
      annual_price: 6465,
    },
  ];
  
  export default Licenses;
  