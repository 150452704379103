
import chapa from "../assets/chapa.png";
import cash from "../assets/cash.png";

const Gateways = [

    {
  id: "1000",
  name: "With Cash",
  icon: cash,
    },
    {
        id: "1001",
        name: "Chapa",
        icon: chapa,
          },
         
];
export default Gateways;